.p-style td {
    border-top: 1px solid #dee2e6 !important;
}

.closinginput {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.closinginput:hover {
    color: #000 !important;
    text-decoration: none;
}

.apexcharts-tooltip #tooltipheader{
    background-color: #f4f8fd !important;
    padding: 5px;
}
.apexcharts-tooltip #tooltipbody{
    padding: 5px;
}
.apexcharts-tooltip #tooltipfooter{
    background-color: #f4f8fd !important;
    padding: 5px;
}
.apexcharts-tooltip #tooltipbody ::marker {
    color: blue;
    font-size: 1.5em;
  }
  
  #customization .customcheckbox .custom-control-label::before {
   
    left: 18.3rem !important;
}
#customization .customcheckbox .custom-control-label::after {
   
    left: 18.3rem !important;
}

#customization .custom-control-label::before {
   
    left: 15rem !important;
}
#customization .custom-control-label::after {
   
    left: 15rem !important;
}

#customization .menuicon_img{
    margin-right: 10px !important;
    vertical-align: sub;
}

@media (max-width:1281px ){
    #customization .customcheckbox .custom-control-label::before {
   
        left: 15.2rem !important;
    }
    #customization .customcheckbox .custom-control-label::after {
       
        left: 15.2rem !important;
    }
    #customization .customcheckbox-mid .custom-control-label::before {
   
        left: 14.9rem !important;
    }
    #customization .customcheckbox-mid .custom-control-label::after {
       
        left: 14.9rem !important;
    }

    #customization .custom-control-label::before {
   
        left: 14.3rem !important;
    }
    #customization .custom-control-label::after {
       
        left: 14.3rem !important;
    }
}
#customization #customize-subtags{
margin-left: 28px;
}
#customization #customizemaintags{
    margin-top:2rem;
}

#notification .custom-control-label::before {
   
    left: 17rem !important;
}
#notification .custom-control-label::after {
   
    left: 17rem !important;
}
#notification .custom-control {
    padding-left: 0px !important;
}